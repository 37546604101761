<script>
import { mapState } from "vuex";
import Languages from "@/components/widgets/languages";

export default {
  locales: {
    pt: {
      'Settings': 'Configurações',
      'My Account': 'Minha Conta',
      'My Address': 'Meus Endereços',
      'Notifications': 'Notificações',
      'Bank Account': 'Conta Bancária',
      'My Password': 'Minha Senha',
      'Password Security' : 'Senha de Segurança',
      'Logout': 'Sair',
    },
    es: {
      'Settings': 'Configuraciones',
      'My Account': 'Mi Cuenta',
      'My Address': 'Mi Endereços',
      'Notifications': 'Notificaciones',
      'Bank Account': 'Cuenta Bancaria',
      'My Password': 'Mi Contraseña',
      'Password Security' : 'Contraseña de Seguridad',
      'Logout': 'Cerrar Sesión',
    }
  },
  components: { Languages },
  props: {
    user: {
      type: Object,
      required: false,
      default: () => ({})
    },
    isCondensed: {
      type: Boolean,
      required: false
    },
    items: {
      default: '0',
    }
  },
  data() {
    return {
      avatar: this.$store.state.account.user.avatar,
    };
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  created() {
  },
  methods: {
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    }
  }
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <button id="vertical-menu-btn" type="button" class="btn btn-sm px-3 font-size-20 header-item" @click="toggleMenu">
          <i class="fa fa-fw fa-bars"></i>
        </button>
      </div>

      <div class="d-flex">
        <router-link tag="a" to="/store" class="logo">
          <img class="mb-1" style="height:25px;" src="@/assets/images/logo-header.png" />
        </router-link>
      </div>

      <div class="d-flex">
        <Languages class="d-none"></Languages>

        <router-link tag="a" to="/store/cart" class="link-cart align-self-center">
          <img style="height:30px;" src="@/assets/images/icon-cart-black.svg" />
          <span class="items">{{ this.items }}</span>
        </router-link>
      </div>
    </div>
  </header>
</template>

<style scoped>
.link-cart {
  padding: 5px 30px 0px 10px;
}
.link-cart i {
  font-size: 1.8rem;
  color: #000;
}
.link-cart .items {
  position: absolute;
  top: 15px;
  right: 20px;
  width: 1.25rem;
  border-radius: 50%;
  color: #fff;
  background: #00bc23;
  font-weight: 600;
  font-size: .8rem;
  line-height: 1.25rem;
  text-align: center;
  opacity: .8;
}
</style>